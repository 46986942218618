import React, { useCallback, useRef } from 'react';
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { AddingCGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, GriduBtnsCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommAxis } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

const tooltipTamplate = (arg) => {
    return {
        text: `${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
    };
};
export function DashMainItemCard(props) {
    const { loading, mainData, gridSelect,
        onRowInsertClick, onRowUpdateClick, onRowDeleteClick
    } = props;
    
    const gridRef = useRef(null);

    const matlDataSource = fwUtil.grid.get.dataSource();
    matlDataSource.store().insert({ init: true }).then(() => matlDataSource.reload());
    for (const row of mainData) {
        const newData = {
            kpis_gubu: row.kpis_gubu || '',
            kpis_name: row.kpis_name || '',
            kpis_unit: row.kpis_unit || '',
            kpis_befo: row.kpis_befo || 0.0,
            kpis_afte: row.kpis_afte || 0.0,
            kpis_valu: row.kpis_valu || 0.0,
        };
        matlDataSource.store().insert(newData);
    }

    const mainGridClick = useCallback((e) => {
        e.data && gridSelect(e.data.kpis_gubu);
    }, [gridSelect]);

    return (
        <DashCard
            title={"KPI 현황"}
            addClass={'main'}
            loading={loading}
        >
            <AddingCGrid
                title={""}
                gridRef={gridRef}
                gridDataSource={matlDataSource}
                width={700}
                onRowClick={mainGridClick}
                allowEdit
                allowInsert
                allowUpdate
                allowDelete
            >
                {CommCol({ dataField: "kpis_gubu", caption: "분야", allowFiltering: false, allowSorting: false, allowResizing: false, width: 50 })}
                {CommCol({ dataField: "kpis_name", caption: "핵심성과지표(KPI)", allowFiltering: false, allowSorting: false, allowResizing: false, width: 140 })}
                {CommCol({ dataField: "kpis_unit", caption: "단위", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_befo", caption: "기존(구축 전)", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_afte", caption: "목표(구축 후)", allowFiltering: false, allowSorting: false, allowResizing: false, allowEditing: false })}
                {DeciCol({ dataField: "kpis_valu", caption: "개선율(달성율)", allowFiltering: false, allowSorting: false, allowResizing: false, allowEditing: false, suffix: "%" })}
                {GriduBtnsCol({ onIclick: onRowInsertClick, onUclick: onRowUpdateClick, onDclick: onRowDeleteClick, })} 
            </AddingCGrid>
            <CommChart
                dataSource={mainData}
                tooltip={{ location: "edge", template: tooltipTamplate }}
                legend={{ vertical: "bottom", horizontal: "center" }}
            >
                {CommAxis({ name: "axis", caption: "", position: "left", start: 0 })}
                {BarsSeri({ valueField: "kpis_valu", caption: "개선(달성율)", color: "#A4F590", args: "kpis_name", })}
                {BarsSeri({ valueField: "kpis_afte", caption: "현재", color: "#8EB5FF", args: "kpis_name", })}
                {BarsSeri({ valueField: "kpis_befo", caption: "목표", color: "#FF8686", args: "kpis_name", })}
            </CommChart>
        </DashCard>
    )
}